import React, { useState } from "react";
import PropType from "prop-types";
import { GlobalContext } from "./GlobalContext";
// This component is a wrapper that contains values and functions other components may need.
// It can be considered the application store.
const GlobalState = (props) => {
  // User State -----------------------------------------------------------------------------
  const [currentUser, setUser] = useState(props.serverUserData);
  const [fetched, setFetched] = useState(false);
  const [userData, updateUserData] = useState({});
  // This method is passed through context to update currentUser Avatar
  const updateUserAvatar = (picture) => {
    setUser({ ...currentUser, picture: picture });
  };

  // Counted State -------------------------------------------------------------------------
  const [counted, setCounted] = useState(true);

  //-----------------------------------------------------------------------------------------

  const updateUser = (userData) => {
    setUser(userData);
  };

  // Modal State -----------------------------------------------------------------------------
  const [isModalOpen, setModalOpenState] = useState(false);
  const [modalToDisplay, setModalToDisplay] = useState("signup");

  // This function will be provided to any function that needs to toggle the modal.
  const toggleModal = () => {
    // Take the previous state and flip it.
    setModalOpenState((prevState) => !prevState);
  };

  // This method is passed through context to update the next modal to open.
  const setModal = (name) => {
    // Take the passed in modal name and set state.
    setModalToDisplay(name);
  };

  // Token Menu State ---------------------------------------------------------------------------
  const [isTokenOpen, setIsTokenOpen] = useState(false);
  const [navTeamCredits, setNavTeamCredits] = useState(0);
  const toggleTokenMenu = () => {
    // Take the previous state and flip it.
    setIsTokenOpen((prevState) => !prevState);
  };
  const hideTokenMenu = () => {
    // Take the previous state and flip it.
    setIsTokenOpen((prevState) => false);
  };
  const showTokenMenu = () => {
    // Take the previous state and flip it.
    setIsTokenOpen((prevState) => true);
  };

  // Loading State ---------------------------------------------------------------------------
  // NOT REACT STATE
  const [loading, setLoadingState] = useState(false);

  // This state will be used as messages in effects to refetch data.
  const [reloadThisData, setWhatToReload] = useState("");

  // User profile id for query ----------------------------------------------------------------
  const [userProfileId, setUserProfileId] = useState("");

  // Flag to determine if the header should change css style.  ----------------------------------------------------------------
  const [adjustBrightness, setAdjustBrightness] = useState(false);

  // This is the object passed to GlobalContext.Provider
  const providerValues = {
    isModalOpen,
    toggleModal,
    modalToDisplay,
    setModal,
    currentUser,
    updateUser,
    loading,
    setLoadingState,
    reloadThisData,
    setWhatToReload,
    userProfileId,
    setUserProfileId,
    adjustBrightness,
    setAdjustBrightness,
    updateUserAvatar,
    isTokenOpen,
    hideTokenMenu,
    showTokenMenu,
    toggleTokenMenu,
    navTeamCredits,
    setNavTeamCredits,
    counted,
    setCounted,
    updateUserData,
    userData,
    fetched,
    setFetched,
  };

  return (
    <GlobalContext.Provider value={providerValues}>
      {props.children}
    </GlobalContext.Provider>
  );
};

export default GlobalState;

GlobalState.propTypes = {
  children: PropType.node,
  user: PropType.object,
  serverUserData: PropType.object,
};
