// api.js
import Axios from "axios";
export const BASE_URL = process.env.NEXT_PUBLIC_API
// export const BASE_URL = "https://api.teammateme.com/"
export const ChatBaseUrl = process.env.NEXT_PUBLIC_API

let urls = {
  test: `https://teammateme-staging.herokuapp.com/`,
  development: "https://api.teammateme.com/",
  production: "https://api.teammateme.com/",
};
const api = Axios.create({
  // baseURL: process.env.NEXT_PUBLIC_BACK_END_URL || urls[process.env.NODE_ENV],
  baseURL:BASE_URL ,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Credentials": true,
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,OPTIONS,PATCH,DELETE,POST,PUT",
    "Access-Control-Allow-Headers":
      "X-CSRF-Token, X-Requested-With, Accept, Accept-Version, Content-Length, Content-MD5, Content-Type, Date, X-Api-Version",
  },
});


export default api;

//test push
