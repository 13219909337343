import Head from "next/head";
import { useRouter } from "next/router";
import { All_Pages } from "utils/constants";

const Meta = () => {
  const router = useRouter();
  const currentPath = router.asPath;
  const pageMetadata = All_Pages[currentPath] || All_Pages["/"];

  return (
    <Head>
      <meta
        name="viewport"
        content="width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0"
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        sizes="16x16"
        type="image/svg+xml"
        href="/favicon16.svg"
      />
      <link
        rel="icon"
        sizes="32x32"
        type="image/svg+xml"
        href="/favicon32.svg"
      />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#324d66" />
      <meta name="msapplication-TileColor" content="#324d66" />
      <meta name="theme-color" content="#324d66" />
      <link
        rel="canonical"
        href={`https://www.teammateme.com${
          router.route !== "/" ? router.asPath : ""
        }`}
      />
      <title>{pageMetadata.title}</title>
      <meta name="description" content={pageMetadata.description} />
      <meta property="og:url" content={pageMetadata["og:url"]} />
      <meta property="og:type" content={pageMetadata["og:type"]} />
      <meta property="og:title" content={pageMetadata.title} />
      <meta property="og:description" content={pageMetadata.description} />
      <meta
        property="og:image"
        content={`https://www.teammateme.com${pageMetadata["og:image"]}`}
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content="teammateme.com" />
      <meta property="twitter:url" content={pageMetadata["og:url"]} />
      <meta name="twitter:title" content={pageMetadata.title} />
      <meta name="twitter:description" content={pageMetadata.description} />
      <meta
        property="twitter:image"
        content={`https://www.teammateme.com${pageMetadata["og:image"]}`}
      />
    </Head>
  );
};

export default Meta;
