import React, { useEffect } from "react";
import GlobalState from "../components/context/GlobalState";
import { MessageContextProvider } from "../components/context/MessageContext";
import PropType from "prop-types";
import Meta from "../components/common/Meta";
import { ToastContainer } from "react-toastify";
import Router from "next/router";
import api from "../components/settings/api";
import Script from "next/script";
import { ThemeProvider, StyledEngineProvider } from "@mui/material";

// Styles
import theme from "../styles/themeMui/theme";
import "../styles/common/index.scss";
import "../styles/landingpage/index.scss";
import "../styles/dashboard/index.scss";
import "../styles/otherpages/index.scss";
import "react-toastify/dist/ReactToastify.min.css";

// Polyfills
import "intersection-observer";
import ProgressBar from "../components/common/ProgressBar";
import Cookies from "universal-cookie";
import { AuthProvider, ProtectRoute } from "components/context/auth";
const cookies = new Cookies();

if (cookies.get("token"))
  api.defaults.headers.common["Authorization"] =
    "Bearer " + cookies.get("token");

function MyApp({ Component, pageProps }) {
  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.getElementById("jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }

    // Router
    const { utm_campaign } = Router.router.query;

    const sendUpdate = async (codeToSend) => {
      await api.put("/api/v1/coupons/code/click", {
        code: codeToSend,
      });
    };

    if (cookies.get("token")) {
      // If a code was found in the query
      if (utm_campaign) {
        // Update that code in the db.
        sendUpdate(utm_campaign);
      } else {
        // Update the no_code value in the db.
        sendUpdate("no_code");
      }
    }

    // Google analytics
    // Router.events.on("routeChangeComplete", this.handleRouteChange);

    // This logic is for handling the #_=_ that facebook appends to the URL after
    // logging in. It is a known issue and this is the current recommended workaround:
    // https://stackoverflow.com/questions/7131909/facebook-callback-appends-to-return-url
    if (window.location.hash === "#_=_") {
      // Check if the browser supports history.replaceState.
      if (history.replaceState) {
        // Keep the exact URL up to the hash.
        var cleanHref = window.location.href.split("#")[0];
        // Replace the URL in the address bar without messing with the back button.
        history.replaceState(null, null, cleanHref);
      } else {
        // Well, you're on an old browser, we can get rid of the _=_ but not the #.
        window.location.hash = "";
      }
    }
  }, []);
  return (
    <>
      <Script
        async
        src={"https://www.googletagmanager.com/gtag/js?id=GTM-TJLLGLR"}
      />
        <Script>
          {`window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag("js", new Date());
                    gtag("config", "GTM-TJLLGLR");`}
        </Script>
      <Meta />
      <ToastContainer />
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <GlobalState {...pageProps}>
            <ProgressBar />
            <MessageContextProvider>
              <AuthProvider>
                <ProtectRoute>
                  <Component {...pageProps} />
                </ProtectRoute>
              </AuthProvider>
            </MessageContextProvider>
          </GlobalState>
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  );
}
export default MyApp;

MyApp.propTypes = {
  Component: PropType.func,
  pageProps: PropType.object,
};
