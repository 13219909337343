import React, { createContext, useState, useContext, useEffect } from "react";
import Cookies from "js-cookie";
import Router, { useRouter } from "next/router";
import api from "components/settings/api";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showErrors, setShowErrors] = useState(false);
  const [errorsArr, setErrorsArr] = useState();
  const router = useRouter();

  async function loadUserFromCookies() {
    try {
      const token = Cookies.get("token");
      if (token) {
        api.defaults.headers.Authorization = `Bearer ${token}`;
        const { data: user } = await api.get("/api/v1/profiles/profile");
        if (user) setUser(user);
      } else {
        if (
          router.pathname === "/dashboard" ||
          router.pathname === "/profile" ||
          router.pathname === "/search" ||
          router.pathname === "/thankyou" ||
          router.pathname === "/home" ||
          router.pathname === "/team/[id]" ||
          router.pathname === "/user/[id]"
        ) {
          Router.push("/");
        }
      }
      setLoading(false);
    } catch (e) {
      logout();
    }
  }

  useEffect(() => {
    loadUserFromCookies();
  }, []);

  const login = async (email, password) => {
    try {
      const resLogin = await api.post("/api/v1/users/login", {
        email,
        password,
      });
      const token = resLogin.data.data.token;
      if (resLogin.data.validationErrors === undefined) {
        api.defaults.headers.Authorization = `Bearer ${token}`;
        const resProfile = await api.get("/api/v1/profiles/profile");

        setUser(resProfile.data);
        setErrorsArr([]);
        setShowErrors(false);
        Cookies.set("token", token, { expires: 60 });
        Router.push("/profile");
        return false;
      } else {
        setErrorsArr([]);
        setShowErrors(false);
      }
    } catch (e) {
      setErrorsArr([]);
      setErrorsArr(e.response.data.validationErrors.errors);
      setShowErrors(true);
      return true;
    }
  };

  const signUp = async (payload) => {
    try {
      const resSignUp = await api.post("/api/v1/users/signup", payload);

      setErrorsArr([]);
      setShowErrors(false);
      api.defaults.headers.Authorization = `Bearer ${resSignUp.data.data.token}`;
      const resProfile = await api.get("/api/v1/profiles/profile");
      setUser(resProfile.data);
      Cookies.set("token", resSignUp.data.data.token, { expires: 60 });
      Router.push("/thankyou?email=true");
      return false;
    } catch (e) {
      setErrorsArr([]);
      setErrorsArr(e.response.data.validationErrors.errors);
      setShowErrors(true);
      return true;
    }
  };

  const signUpInvite = async (payload) => {
    try {
      const resSignUp = await api.post("/api/v1/users/signup/link", payload);

      setErrorsArr([]);
      setShowErrors(false);
      api.defaults.headers.Authorization = `Bearer ${resSignUp.data.data.token}`;
      const resProfile = await api.get("/api/v1/profiles/profile");
      setUser(resProfile.data);
      Cookies.set("token", resSignUp.data.data.token, { expires: 60 });
      Router.push("/thankyou?email=true");
      return false;
    } catch (e) {
      setErrorsArr([]);
      setErrorsArr(e.response.data.validationErrors.errors);
      setShowErrors(true);
      return true;
    }
  };

  const addPublicMember = async (payload) => {
    let flag = false;
    await api
      .post("/api/v1/users/addInactive", {
        email: payload.email,
        fullname: payload.fullname,
        location: payload.location,
        university: payload.university,
        major: payload.major,
        profession: payload.profession,
        skills: payload.skills,
      })
      .then(async (res) => {
        // Clear any errors
        setErrorsArr([]);
        // Hide the errors component
        setShowErrors(false);
      })
      .catch((err) => {
        if (err.response.status !== 500) {
          setErrorsArr(err);
          setShowErrors(true);
        }
        flag = true
      });
      return flag
  };

  const getUser = async (token) => {
    Cookies.set("token", token, { expires: 60 });
    api.defaults.headers.Authorization = `Bearer ${token}`;
    const resProfile = await api.get("/api/v1/profiles/profile");
    setUser(resProfile.data);
  };

  const logout = () => {
    Cookies.remove("token");
    Cookies.remove("token", { domain: "teammateme.com" });
    Cookies.remove("token", { domain: "teammateme.net" });
    Cookies.remove("__cf_bm", { domain: "teammateme.net" });
    setUser(null);
    delete api.defaults.headers.Authorization;
    Router.push("/");
  };

  const reportIssue = async (payload) => {
    try {
      const res = await api.post("/api/v1/report/issue", payload);
      return res;
    } catch (e) {
      // setErrorsArr([]);
      // console.log(e.response)
      // setErrorsArr(e.response.data.validationErrors.errors);
      // setShowErrors(true);
      // console.log(e.response.data)
      return e.response;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: !!user,
        user,
        login,
        setUser,
        loading,
        logout,
        showErrors,
        errorsArr,
        getUser,
        signUp,
        signUpInvite,
        reportIssue,
        addPublicMember
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

export const ProtectRoute = ({ children }) => {
  const { isLoading } = useAuth();
  if (isLoading) {
    return "loading...";
  }
  return children;
};
