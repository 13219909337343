import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "16px",
          letterSpacing: 1,
          textTransform: "capitalize"
        },
        outlinedPrimary: {
          borderWidth: "2px",
          borderColor: "#2eb790",
          fontWeight: "600",
          "&:hover": {
            borderWidth: "2px",
          },
        },
      }
    }
  },
  palette: {
    primary: {
      main: "#2eb790",
      dark: "#009978",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#FFFFFF",
      dark: "rgba(0, 183, 144, 0.1)",
      light: "rgba(0, 183, 144, 0.24)",
    },
    teamMate: {
      primary: {
        blue: "#2eb790",
        green: "#001B11",
        grayScaleGreen: "rgba(0, 27, 17, 0.7)",
      },
      black: "#000000",
      background: {
        silverGrey: "#F5F4F2",
      },
      darkGrey: "#F5F4F2",
      lightGrey: "#E0E0E0",
      gradientGreen: "rgba(220, 241, 236, 0.5)",
      gradientDarkGreen: "rgba(220, 241, 236, 0.9)",
    },
    white: "#fff",
  },
  typography: {
    fontFamily: ["Open Sans"].join(","),
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": ["Open Sans"],
        borderWidth: "2px",
      },
    },
    MuiButton: {
      root: {
        borderRadius: "24px",
        fontSize: "16px",
        letterSpacing: 1,
      },
      outlinedPrimary: {
        borderWidth: "2px",
        borderColor: "#2eb790",
        fontWeight: "600",
        "&:hover": {
          borderWidth: "2px",
        },
      },
    },
  },
  breakpoints: {
    values: {
      xxs: 0,
      xs: 420,
      sm: 720,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

export default createTheme(theme);
