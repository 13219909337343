import { createContext, useContext, useState } from "react";
const messageContext = createContext();
export const MessageContextProvider = ({ children }) => {
  const [activeUserToMessage, setActiveUserToMessage] = useState({});
  const [isOpenMobileChat, toggleMobileChat] = useState(false);
  const [hasNewMessage, setHaseNewMessage] = useState(false);
  const [showMobileNav, setShowMobileNav] = useState(false);

  const updateActiveUserToMessage = (user) => {
    setActiveUserToMessage(user);
  };

  return (
    <messageContext.Provider
      value={{
        updateActiveUserToMessage,
        activeUserToMessage,
        isOpenMobileChat,
        toggleMobileChat,
        hasNewMessage,
        setHaseNewMessage,
        showMobileNav,
        setShowMobileNav
      }}
    >
      {children}
    </messageContext.Provider>
  );
};
export const useMessageContextProvider = () => {
  return useContext(messageContext);
};
