export const IMAGES = {
  emoji: "/images/svg/emoji.svg",
  edit: "/images/svg/edit.svg",
  messageIcon: "/images/svg/messageIcon.svg",
};

export const CHAT_ENDPOINT = "http://127.0.0.1:8080";

export const EMOJIS = [
  "🙏",
  "🥰",
  "😍",
  "🔥",
  "🤣",
  "🥺",
  "❤️",
  "✨",
  "😂",
  "😭",
  "😊",
  "😘",
  "👍",
  "👏",
  "😁",
  "💔",
  "🙄",
  "😆",
  "🤔",
  "👌",
  "😇",
  "🎉",
  "😕",
  "✅",
  "🙂",
  "🙁",
  "👀",
  "🤦🏼‍♂️",
  "👋",
  "😉",
  "😃",
  "😞",
  "😡",
  "🤬",
  "🤯",
  "😑",
  "😴",
  "🤕",
  "😎",
  "😒",
];

export const CHAT_DATE_FORMAT = "MMMM DD, YYYY, hh:mm A";

export const DATE_CHAT = "MMM DD";

export const TIME_CHAT = "hh:mm";

export const NOTIFICATION_SOUND =
  "https://notificationsounds.com/storage/sounds/file-sounds-1151-swiftly.mp3";

export const ourTeamArr = [
  {
    name: "Daniel Armani",
    role: "Founder",
    imgSrc: "profile_picture_hamed.jpeg",
    imgAlt: "Dr. Daniel Armani",
    bio: (
      <span>
        <a
          href="https://www.governplanet.org"
          target="_blank"
          rel="noreferrer noopener"
          className="link link--name_link"
        >
          Dr. Armani
        </a>
        is an institutional economist and data scientist working on Collective
        Intelligence, FinTech, Blockchain Technology, Smart Contracts, and
        Distributed Autonomous Organizations (DAOs). He has designed and
        experimented with different Collective Design protocols at the
        University of Texas at Austin (as the Principal Investigator) and at
        Michigan State University.
      </span>
    ),
  },
  {
    name: "Jenny Jacoby",
    role: "Executive Assistant",
    imgSrc: "JJ.jpg",
    bio: "Jenny earned her Bachelor's degree in Philosophy, Politics, and Law at Binghamton University in the top 5% of her class. She is earning her Master in Business Administration with a concentration in marketing at Binghamton University. She has been a marketing analyst at several companies with certifications in Google analytics.",
  },
  {
    name: "Steve Long",
    role: "Contributor",
    imgSrc: "0.jpg",
    bio: "Steve is a full stack software engineer with almost ten years experience in software. A certified specialist in NodeJS and Cloud-based technologies, he has a passion for developing apps people love. He enjoys contributing to start ups and architecting solutions from the ground floor. His interests in science and technology range from the practical to the theoretical.",
  },
  {
    name: "Jordan Walker",
    role: "Developer",
    imgSrc: "profile_picture_jordan.jpg",
    bio: "As a full-stack web developer, I love to help clients turn their ideas into visually appealing applications. I do this by using my knowledge of client-side and server-side technologies to create a modern, user friendly, and secure interface that meets the needs of my clients and their users. In the future, I would like to apply my skills and experience on a larger development team.",
  },
  {
    name: "Amelia Espiritu-Santo",
    role: "UX Designer & Researcher",
    imgSrc: "ae.jpg",
    bio: "Amelia is a recent computer science graduate, specializing in UX design and UX research. She believes technology should be designed to be accessible and inclusive. Amelia loves to collaborate with others and learn how they approach challenging tasks. She has been a researcher and designer for several projects. At TeammateMe, she will continue to learn and design to help bring their innovations to life.",
  },
  {
    name: "Kerry Smith",
    role: "Lead Developer & DevOps",
    imgSrc: "hackerman.jpg",
    bio: "Kerry is a full stack software engineer with many years of experience in many technology fields.  He enjoys science, technology, and anything in outer-space. Since he was little he always loved taking things apart just so to put them back together again, and then do it all over again! Working with TeammateMe affords him the opprotunity to work with a diverse group of individuals from all over the world. He believes this platform will revolutionize team building from the ground up and is happy to be apart of this team.",
  },
  {
    name: "Ramsey Lewis",
    role: "Frontend Developer",
    imgSrc: "Ramsey.jpg",
    bio: "Ramsey is a Frontend  software engineer with years in technology and design. With a background in formal analysis in art during his years at Fashion Institute of Technology and years in agile web development. These attributes come together to make a creative & experienced frontend software engineer with a robust attention to detail. His goal is to make every project feel like a home or place of business, with a flow that feels as natural as carrying a conversation.",
  },
  {
    name: "Giselle Burges",
    role: "UX Designer",
    imgSrc: "Giselle.jpg",
    bio: "Giselle is a UX designer with a background in healthcare and research. She graduated from the University of Washington with a Psychology degree and strives to create beautiful, easy digital experiences for users. Her other interests include anything related to biological science, travel, and cooking.",
  },
  {
    name: "Reza Zeraat",
    role: "Lead Web Developer",
    imgSrc: "Reza.jpg",
    bio: "Reza is a hard working and ambitious web developer that loves to experience new adventures. Throughout his programming journey he has worked with many languages and frameworks, but ultimately found React web development to be his preference. He now has over 3 years of web development experience using React, and although this is his specialization, his knowledge of back-end technologies is extensive.",
  },
  {
    name: "Braden Hanna",
    role: "Data Analyst",
    imgSrc: "Braden.jpeg",
    bio: "Braden is an AI/ML graduate student. The moment that inspired him down the AI/ML path occurred during his undergraduate studies. As a Biology major, he had to take a biostatistics class, taught in R, and quickly found an affinity for it. Not only did the intersection of statistics and programming interest him, but it made him hungry for more, so much so that he switched his major to Computer Science. His main passion is computer vision; however, he finds joy working with anything data-related.",
  },
];

export const ACTIVE_VARIANTS = [
  "control",
  "variant1",
  "variant2",
  "variant3",
  "variant8",
  "variant9",
  "variant10",
  "variant11",
];

export const OUR_CAREER_ARR = [
  {
    title: "Marketing Manager",
    postedOn: "01-13-2022",
    type: "Full-time",
    details: "/link/tothiscareer/1",
    location: "Remote",
    link: "https://www.linkedin.com/company/teammateme/jobs/",
    buttonOnChange: "Go to LinkedIn",
    description:
      " Looking for a marketing manager who can use online tools and social media marketing techniques efficiently and effectively to expand the user base of the website. In the beginning, it is a part-time position, but it can become full-time upon demonstrating outstanding results and high performance during 2021.",
    qualifications: [
      " 2+ years of online marketing experience",
      " SEO and SEM",
      " Advertisement design skills",
      " Funneling Optimization",
      " UI/UX Design for Funneling",
      " BA or MA in Marketing or related field",
    ],
  },
  {
    title: "Web Developer",
    postedOn: "01-13-2022",
    type: "Full-time",
    details: "/link/tothiscareer/2",
    location: "Remote",
    link: "https://www.linkedin.com/company/teammateme/jobs/",
    buttonOnChange: "Go to LinkedIn",
    description:
      "We need a Full Stack React developer who knows Next.js and Express.js. You also need to know how to use Figma and Github. Familiarity with Material UI is a plus. This position is part-time at the beginning, but it can become full-time upon demonstrating high performance and coding skills.",
    qualifications: [
      " BA or MS in Computer Science or related field. ",
      " Knowledge of Next.js and Express.js ",
      " Experience with Figma & Github ",
    ],
  },
  {
    title: "Marketing Assistant",
    postedOn: "01-13-2022",
    type: "Part-time",
    details: "/link/tothiscareer/3",
    location: "Remote",
    link: "https://www.linkedin.com/company/teammateme/jobs/",
    buttonOnChange: "Go to LinkedIn",
    description:
      "The ideal candidate will be responsible for creating marketing content and distributing it effectively. You will help us create a strong brand presence with innovative promotional offerings, online and offline marketing campaigns, and marketing events. Finally, your analytical skills will assist you as you monitor and report on the success of your marketing efforts. This position is part-time at the beginning, but it can become full-time in near future.",
    qualifications: [
      "Acquire more users and sign-ups",
      " Plan and execute campaigns and events",
      " Track, analyze, and report the success of those campaigns and events",
      " Create online and offline marketing content",
      " 1-3 years’ of marketing experience especially using social medi",
      " BS/BA in Marketing or a related field of study",
      " Excellent writing and communication skills",
      " Proficiency with google analytics and google search console",
    ],
  },
];

export const srcArray = [
  { src: "https://www.youtube.com/embed/uVN6vxaI8C0?autoplay=0&rel=0" },
  { src: "https://www.youtube.com/embed/e5dOocYNoMw?autoplay=0&rel=0" },
  { src: "https://www.youtube.com/embed/cOGlw9_e-MA?autoplay=0&rel=0" },
  { src: "https://www.youtube.com/embed/-YOBNSuWgPo?autoplay=0&rel=0" },
  { src: "https://www.youtube.com/embed/x65Jj8yDavU?autoplay=0&rel=0" },
  { src: "https://www.youtube.com/embed/8cEHbOXV05E?autoplay=0&rel=0" },
  { src: "https://www.youtube.com/embed/Tjrbrj2Rfx8?autoplay=0&rel=0" },
  { src: "https://www.youtube.com/embed/fQHwLuI82uE?autoplay=0&rel=0" },
  { src: "https://www.youtube.com/embed/YV0f3T9ryOM?autoplay=0&rel=0" },
  { src: "https://www.youtube.com/embed/VErn9chTbCA?autoplay=0&rel=0" },
];

export const blogPages = [
  {
    postTitle: "The Best Team Building Activities for Remote Teams",
    postCover: "/blogContent/blog-cover-14-remoteTeamActivities-cropped",
    postAuthor: "Keren Dinkin",
    postDate: "December 6, 2021",
    postLinkPath: "/posts/team-building-activities",
    description:
      "Research shows that 71% of employees currently work from home (Pewresearch.org).",
    source: "TeammateMe",
    infographic: "/blogContent/infographic/remoteTeamsActivities.jpg",
    navigation: "blog/team-building-activities",
    id: 14,
    address: "team-building-activities",
  },
  {
    postTitle: "Best strategies for building a professional network",
    postCover: "/blogContent/blog-cover-15-remoteTeamActivities-cropped",
    description:
      "For many, networking may sound like a stuffy term that brings forth the image of a room filled with elite, wealthy, and not-so-diverse people.",
    postAuthor: "Acacia Fante",
    postDate: "December 6, 2021",
    postLinkPath: "/posts/prostrategies",
    source: "TeammateMe",
    infographic: "/blogContent/infographic/networking.jpg",
    navigation: "blog/prostrategies",
    id: 0,
    address: "prostrategies",
  },
  {
    postTitle: "How to build a powerful team online",
    postCover: "/blogContent/blog-cover-13-poweronlineteams-cropped",
    description:
      "Naturally, we are used to working with people face-to-face, and it can be difficult to find out how to get the same results from a team that works entirely online.",
    postAuthor: "Hannah West",
    postDate: "November 15, 2021",
    postLinkPath: "/posts/build-powerful-team-online",
    source: "TeammateMe",
    infographic: "/blogContent/infographic/powerfulteamonline.jpg",
    navigation: "blog/build-powerful-team-online",
    id: 1,
    address: "build-powerful-team-online",
  },
  {
    postTitle: "Effective Team Management",
    postCover: "/blogContent/blog-cover-12-Effective-Online-Group-Projects",
    description:
      "That sentence can be a source of stress for the best of us. There are a wide range of skills, viewpoints, motives, and resources in any environment.",
    postAuthor: "Jennifer Inglis",
    postDate: "November 15, 2021",
    postLinkPath: "/posts/effective-team-management",
    source: "TeammateMe",
    infographic:
      "/blogContent/infographic/EffectiveOnlineGroupProjectsInforGraphic.jpg",
    navigation: "blog/effective-team-management",
    id: 2,
    address: "effective-team-management",
  },
  {
    postTitle: "Online Tools for Researchers and Scholars",
    postCover: "/blogContent/blog-cover-11-online-tools-cropped",
    description:
      "Careful and thorough research requires time, information, collaboration, and organization.",
    postAuthor: "Jennifer Inglis & Amber Dean",
    postDate: "October 31, 2021",
    postLinkPath: "/posts/onlinetools",
    source: "TeammateMe",
    infographic:
      "/blogContent/infographic/infographic-online-tools-for-researchers.png",
    navigation: "blog/onlinetools",
    id: 3,
    address: "onlinetools",
  },
  {
    postTitle: "Top Characteristics of a Good Teammate",
    description:
      "Teamwork is vital, and it's taught early through games, sports, and group activities.  ",
    postCover: "/blogContent/blog-cover-10-good-teammate",
    postAuthor: "Jane Leonhardt",
    postDate: "November 19, 2021",
    postLinkPath: "/posts/characteristics-good-teammate",
    source: "TeammateMe",
    infographic:
      "/blogContent/infographic/TopCharacteristicsOfAGoodTeammateInfographic.jpg",
    navigation: "blog/characteristics-good-teammate",
    id: 4,
    address: "characteristics-good-teammate",
  },
  {
    postTitle: "The Best Places to Find Professional Partners and Teammates",
    description:
      "Starting a project? Building a strong team is key. Discover teammates through your network, conferences, social media, and TeammateMe.",
    postCover:
      "/blogContent/blog-cover-1-pexels-buro-millennial-1438072-cropped",
    postAuthor: "Erin Nordhof",
    postDate: "April 8, 2022",
    postLinkPath: "/posts/places-to-find-professional-teammates",
    source: "TeammateMe",
    infographic: "/blogContent/infographic/teammateme-02.jpg",
    navigation: "blog/places-to-find-professional-teammates",
    id: 5,
    address: "places-to-find-professional-teammates",
  },
  {
    postTitle: "How to Manage Study Groups Online",
    description:
      "So, you want to form an online study group? Research in the field of Educational Psychology shows that study groups improve learning.",
    postCover:
      "/blogContent/blog-cover-2-pexels-frans-van-heerden-632470-cropped",
    postAuthor: "Christina Curry",
    postDate: "November 5, 2021",
    postLinkPath: "/posts/onlinestudygroups",
    source: "TeammateMe",
    infographic: "/blogContent/infographic/onlineteam.jpg",
    infographicLarge: "/blogContent/infographic/onlineteam.jpg",
    navigation: "blog/onlinestudygroups",
    id: 6,
    address: "onlinestudygroups",
  },
  {
    postTitle: "Four Ways to Motivate a Team",
    postCover: "/blogContent/blog-cover-3-pexels-pixabay-261909-cropped",
    description:
      "In today's workplace, employee motivation is critical for success.",
    postAuthor: "Abby Cribbs",
    postDate: "December 3, 2021",
    postLinkPath: "/posts/fourways",
    source: "TeammateMe",
    infographic: "/blogContent/infographic/fourways.png",
    navigation: "blog/fourways",
    id: 7,
    address: "fourways",
  },
  {
    postTitle: "Why Teamwork Matters: Reasons to Work as a Team",
    postCover:
      "/blogContent/blog-cover-4-pexels-roxanne-minnish-4514045-cropped",
    postAuthor: "Keren Dinkin",
    description:
      "Working with a team of people can be emotionally draining, especially if you’re no social butterfly.",
    postDate: "November 22, 2021",
    postLinkPath: "/posts/whywork",
    source: "TeammateMe",
    infographic: "/blogContent/infographic/whywork.jpg",
    navigation: "blog/whywork",
    id: 8,
    address: "whywork",
  },
  {
    postTitle: "Cheat Sheet for Predictably Irrational",
    description:
      "This book challenges the common misconception that humans behave in fundamentally rational ways.",
    postCover:
      "/blogContent/blog-cover-5-lala-azizli-tfNyTfJpKvc-unsplash-cropped",
    postAuthor: "Abby Cribbs",
    postDate: "October 31, 2021",
    postLinkPath: "/posts/cheatsheet",
    source: "TeammateMe",
    infographic: "/blogContent/infographic/cheatsheet.png",
    navigation: "blog/cheatsheet",
    id: 9,
    address: "cheatsheet",
  },
  {
    postTitle: "Top Tools for Remote Collaboration",
    description:
      "Remote work is on the rise, making tools for remote collaboration a professional necessity.",
    postCover:
      "/blogContent/blog-cover-6-kaleidico-RDYdOvk8ats-unsplash-cropped",
    postAuthor: "Alexis Harborth",
    postDate: "October 18, 2021",
    postLinkPath: "/posts/remotecollabtools",
    source: "TeammateMe",
    infographic: "/blogContent/infographic/TopRemoteCollab.jpg",
    navigation: "blog/remotecollabtools",
    id: 10,
    address: "remotecollabtools",
  },
  {
    postTitle: "Building a Better Team Culture",
    description:
      "Have you heard? We've entered the age of the great resignation — the name given to the recent phenomena of large numbers of people quitting their jobs.",
    postCover:
      "/blogContent/blog-cover-7-How-Group-Projects-Help-Students-Develop-Leadership-Skills-cropped",
    postAuthor: "Ben Nettleton",
    postDate: "December 20, 2021",
    postLinkPath: "/posts/building-better-team-culture",
    source: "TeammateMe",
    infographic: "/blogContent/infographic/betterteamculture.png",
    navigation: "blog/building-better-team-culture",
    id: 11,
    address: "building-better-team-culture",
  },
  {
    postTitle: "8 Free Tools For Remote Teaching",
    description:
      "In today’s tech-driven world, educators must master tools for remote teaching.",
    postCover:
      "/blogContent/blog-cover-8-edvin-johansson-UGGinojhaC0-unsplash-cropped",
    postAuthor: "Abby Cribbs",
    postDate: "August 31, 2021",
    postLinkPath: "/posts/eightfree",
    source: "TeammateMe",
    infographic: "/blogContent/infographic/RemoteTeachingTools.png",
    navigation: "blog/eightfree",
    id: 12,
    address: "eightfree",
  },
  {
    postTitle: "8 Ways to Learn Faster",
    description:
      "Learning new things can be tricky but the value of personal & professional growth & development is immense.",
    postCover: "/blogContent/blog-cover-9-akson-1K8pIbIrhkQ-unsplash-cropped",
    postAuthor: "Abby Cribbs",
    postDate: "August 7, 2021",
    postLinkPath: "/posts/eightways",
    source: "TeammateMe",
    infographic: "/blogContent/infographic/learnfaster.png",
    navigation: "blog/eightways",
    id: 13,
    address: "eightways",
  },
];

const Static_Pages = {
  "/": {
    title: "TeammateMe - Your Teambuilding Platform.",
    description:
      "TeammateMe is an easy-to-use online platform that enables users to form teams, join teams, and find teammates for their projects.",
    "og:url": process.env.NEXT_PUBLIC_BASE_URL,
    "og:type": "website",
    "og:image": "/teammateme_banner_img.jpg",
    "twitter:card": "summary_large_image",
    "twitter:domain": "teammateme.com",
  },
  "/home": {
    title: "TeammateMe - Your Teambuilding Platform.",
    description:
      "TeammateMe is an easy-to-use online platform that enables users to form teams, join teams, and find teammates for their projects.",
    "og:url": process.env.NEXT_PUBLIC_BASE_URL,
    "og:type": "website",
    "og:image": "/teammateme_banner_img.jpg",
    "twitter:card": "summary_large_image",
    "twitter:domain": "teammateme.com",
  },
  "/search": {
    title: "Search | TeammateMe",
    description:
      "Research shows that 71% of employees currently work from home (Pewresearch.org). Of these, many work in teams composed of people from across the globe.",
    "og:url": process.env.NEXT_PUBLIC_BASE_URL + "/search",
    "og:type": "website",
    "og:image": "/search-page.jpg",
    "twitter:card": "summary_large_image",
    "twitter:domain": "teammateme.com",
  },
  "/blogroll": {
    title: "Blog | TeammateMe",
    description:
      "TeammateMe has a lot of posts that could help your teams do better.",
    "og:url": process.env.NEXT_PUBLIC_BASE_URL + "/blogroll",
    "og:type": "website",
    "og:image": "/blogroll-page.jpg",
    "twitter:card": "summary_large_image",
    "twitter:domain": "teammateme.com",
  },
  "/helpdoc": {
    title: "Help Document | TeammateMe",
    description:
      "The mission of the Platform is to provide the services detailed in the Platform home page and help page (collectively the Services).",
    "og:url": process.env.NEXT_PUBLIC_BASE_URL + "/helpdoc",
    "og:type": "website",
    "og:image": "/helpdoc-page.jpg",
    "twitter:card": "summary_large_image",
    "twitter:domain": "teammateme.com/helpdoc",
  },
  "/help": {
    title: "Help | TeammateMe",
    description:
      "TeammateMe is a global online platform revolutionizing the process of team building.",
    "og:url": process.env.NEXT_PUBLIC_BASE_URL + "/help",
    "og:type": "website",
    "og:image": "/help-page.jpg",
    "twitter:card": "summary_large_image",
    "twitter:domain": process.env.NEXT_PUBLIC_BASE_URL + "/help",
  },
  "/about": {
    title: "About | TeammateMe",
    description:
      "Here you can get familiar with all the people of the TeammateMe platform",
    "og:url": process.env.NEXT_PUBLIC_BASE_URL + "/about",
    "og:type": "website",
    "og:image": "/about-page.jpg",
    "twitter:card": "summary_large_image",
    "twitter:domain": "teammateme.com/about",
  },
  "/careers": {
    title: "Career | TeammateMe",
    description:
      "TeammateMe’s mission is to facilitate team formation, partnerships and collaboration.",
    "og:url": process.env.NEXT_PUBLIC_BASE_URL + "/careers",
    "og:type": "website",
    "og:image": "/career-page.jpg",
    "twitter:card": "summary_large_image",
    "twitter:domain": "teammateme.com/careers",
  },
  "/termsandconditions": {
    title: "Terms and Conditions | TeammateMe",
    description:
      "These Terms of Use contain ground rules for your use of the Services (the Terms).",
    "og:url": process.env.NEXT_PUBLIC_BASE_URL + "/termsandconditions",
    "og:type": "website",
    "og:image": "/tearmsandconditions.jpg",
    "twitter:card": "summary_large_image",
    "twitter:domain": "teammateme.com/termsandconditions",
  },
  "/privacypolicy": {
    title: "Privacy Policy | TeammateMe",
    description:
      "This privacy policy applies to our websites, apps and other services, including programs and events, that refer or link to this privacy policy.",
    "og:url": process.env.NEXT_PUBLIC_BASE_URL + "/privacypolicy",
    "og:type": "website",
    "og:image": "/privacypolicy.jpg",
    "twitter:card": "summary_large_image",
    "twitter:domain": "teammateme.com/privacypolicy",
  },
  "/cookies": {
    title: "Cookie Policy | TeammateMe",
    description:
      "Cookies are simple text files that are stored on your computer or mobile device by a website’s server. Each cookie is unique to your web browser.",
    "og:url": process.env.NEXT_PUBLIC_BASE_URL + "/cookies",
    "og:type": "website",
    "og:image": "/cookie-page.jpg",
    "twitter:card": "summary_large_image",
    "twitter:domain": "teammateme.com/cookies",
  },
};
const Dynamic_Pages = {};

blogPages.forEach((e) => {
  Dynamic_Pages["/" + e.navigation] = {
    title: e.postTitle,
    description: e.description,
    "og:url": "https://www.teammateme.com/" + e.navigation,
    "og:type": "article",
    "og:image": e.postCover + "-large.jpg",
    "twitter:card": "summary_large_image",
    "twitter:domain": "teammateme.com",
    "twitter:url": "https://www.teammateme.com/" + e.navigation,
  };
});

export const All_Pages = { ...Static_Pages, ...Dynamic_Pages };
