import React from "react";

const globalStateDefaults = {
  modals: {
    isAuthModalOpen: false,
    modalToDisplay: "signup",
    toggleModal: () => {},
    setModalToDisplay: () => {},
  },
  user: undefined,
  navTeamCredits: 0,
  setNavTeamCredits: () => {},
  pageName: undefined,
  loading: false,
  teamProfileId: "",
  userProfileId: "",
  serverUserData: "",
  counted: true,
  tokenMenu: {
    open: false,
    toggleTokenMenu: () => {},
    hideTokenMenu: () => {},
    showTokenMenu: () => {},
  },
};

export const GlobalContext = React.createContext(globalStateDefaults);
